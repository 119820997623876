import React, { Component } from 'react';
import { Auth } from "aws-amplify";
import { Button, Form, Spinner, Alert } from 'react-bootstrap';
import logo from '../logo.svg';


class ForgotPassword extends Component {
    constructor(props){
        super(props);
        
        this.state = {
            isLoading: false,
            emailSent: false,
            email: "",
            code: "",
            newPassword: ""
        };
    }
    
    HandleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }
    
    ValidateForm(){
        return(this.state.email.length > 0);
    }
    
    ValidateConfirmation(){
        return(
            this.state.code.length > 0 &&
            this.state.newPassword.length > 6
        );
    }
    
    HandleSubmit = async event => {
        event.preventDefault();
        
        this.setState({ isLoading: true });
        
        try {
            await Auth.forgotPassword(this.state.email);
            
            this.setState({
                isLoading: false,
                emailSent: true
            });
        } catch(e) {
            alert(e['message']);
            this.setState({ isLoading: false });
        }
    }
    
    HandleConfirmation = async event => {
        event.preventDefault();
        
        this.setState({ isLoading: true });
        
        try {
            await Auth.forgotPasswordSubmit(this.state.email, this.state.code, this.state.newPassword);
            
            this.props.history.push("/login");
        } catch(e) {
            alert(e['message']);
            this.setState({ isLoading: false });
        }
    }
    
    DisplayConfirmation(){
        return(
            <div className="Alert">
                <Alert variant="primary">
                    An email with a confirmation code has been sent to your address. Please use it to complete the form below.
                </Alert>
                <div className="ConfirmationForm" style={{ justifyContent:'center', alignItems:'center', display: 'flex' }}>
                    <Form onSubmit={this.HandleConfirmation}>
                        <Form.Group controlId="code">
                            <Form.Control 
                                placeholder="Confirmation Code"
                                type="text"
                                value={this.state.code}
                                onChange={this.HandleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="newPassword">
                            <Form.Control 
                                placeholder="New Password"
                                type="password"
                                value={this.state.newPassword}
                                onChange={this.HandleChange}
                            />
                        </Form.Group>
                        {this.state.isLoading === true ? <Button variant="primary" block disabled><Spinner animation="grow" size="sm" />Update Password</Button> : <Button variant="primary" block disabled={!this.ValidateConfirmation()} type="submit">Update Password</Button>}
                    </Form>
                </div>
            </div>
        );
    }
    
    DisplayForm(){
        return(
            <div className="ResetForm" style={{ justifyContent:'center', alignItems:'center', display: 'flex' }}>
                <Form onSubmit={this.HandleSubmit}>
                    <Form.Group controlId="email">
                        <Form.Control 
                            placeholder="Email Address"
                            type="email"
                            value={this.state.email}
                            onChange={this.HandleChange}
                        />
                    </Form.Group>
                    {this.state.isLoading === true ? <Button variant="primary" block disabled><Spinner animation="grow" size="sm" />Reset Password</Button> : <Button variant="primary" block disabled={!this.ValidateForm()} type="submit">Reset Password</Button>}
                </Form>
            </div>
        );
    }
    
    render(){
        return(
            <div className="ForgotPassword">
                <img src={logo} width="100" height="100" alt="logo"/><h4>TinyPath</h4>
                {this.state.emailSent === false
                    ? this.DisplayForm()
                    : this.DisplayConfirmation()
                }
            </div>
        );
    }
}

export default ForgotPassword;