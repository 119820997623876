import React from "react";
import { Switch } from "react-router-dom";
import DisplayBody from "./components/DisplayBody";
import Login from "./components/Login";
import NotFound from "./components/NotFound";
import ChangePassword from "./components/ChangePassword";
import ForgotPassword from "./components/ForgotPassword";
import SamlLogin from "./components/SamlLogin";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";


export default ({ childProps }) =>
    <Switch>
        <AuthenticatedRoute path="/" exact component={DisplayBody} props={childProps} />
        <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
        <AuthenticatedRoute path="/changepassword" exact component={ChangePassword} props={childProps} />
        <UnauthenticatedRoute path="/forgotpassword" exact component={ForgotPassword} props={childProps} />
        <UnauthenticatedRoute path="/samllogin" exact component={SamlLogin} props={childProps} />
        <AuthenticatedRoute component={NotFound} props={childProps}/>
    </Switch>;