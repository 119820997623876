export default {
    apiGateway: {
        REGION: "us-east-2",
        URL: "https://api.tinypath.net"
    },
    cognito: {
        REGION: "us-east-2",
        USER_POOL_ID: "us-east-2_c62E2orfb",
        APP_CLIENT_ID: "324ddh76a5n291scsv0n23e4l",
        IDENTITY_POOL_ID: "us-east-2:2c2d201e-c49d-44cb-9a56-ccc64276eb92"
    },
    oauth: {
        DOMAIN: "tinypath.auth.us-east-2.amazoncognito.com",
        CALLBACK: "https://admin.tinypath.net",
        SIGN_OUT: "https://admin.tinypath.net/login"
    }
};