import React, { Component } from 'react';
import { Auth } from "aws-amplify";
import { Button, Form, Spinner, Alert } from 'react-bootstrap';
import logo from '../logo.svg';


class ChangePassword extends Component {
    constructor(props){
        super(props);
        
        this.state = {
            isLoading: false,
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
            changeConfirmed: false,
            userName: "",
            samlLogin: false
        };
    }
    
    async componentDidMount() {
        Auth.currentAuthenticatedUser().then(user => {
            if(user.hasOwnProperty('attributes')) {
                this.setState({ userName: user['attributes']['email'] });
            }else {
                this.setState({
                    samlLogin: true
                });
            }
        });
    }
    
    HandleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }
    
    ValidatePassword(){
        return(
            this.state.currentPassword.length > 6 && 
            this.state.newPassword.length > 6 &&
            this.state.confirmPassword.length > 0 &&
            this.state.newPassword === this.state.confirmPassword
        );
        
    }
    
    HandlePasswordChange = async event => {
        event.preventDefault();
        
        this.setState({ isLoading: true });
        
        try {
            await Auth.currentAuthenticatedUser().then(user => {
                return Auth.changePassword(user, this.state.currentPassword, this.state.newPassword);
            });
            
            this.setState({ changeConfirmed: true });
            
            setTimeout(() => {
                this.props.history.push("/");
            }, 5000);
        } catch(e) {
            alert(e['message']);
            this.setState({ isLoading: false });
        }
    }
    
    HandleCancel(){
        this.props.history.push("/");
    }
    
    DisplaySamlUser(){
        return(
            <div className="SamlUser" style={{ justifyContent:'center', alignItems:'center', display: 'flex' }}>
                <Alert variant="warning">
                    You are currently logged in through a federation provider. Please change your password with that provider.
                </Alert>
            </div>
        );
    }
    
    DisplayConfirmation(){
        return(
            <div className="Confirmation" style={{ justifyContent:'center', alignItems:'center', display: 'flex' }}>
                <Alert variant="success">
                    <strong>Password Updated Successfully.</strong><br />
                    You will now be redirected to the home page.
                </Alert>
            </div>
        );
    }
    
    DisplayForm(){
        return(
            <div className="PasswordForm" style={{ justifyContent:'center', alignItems:'center', display: 'flex' }}>
                <Form onSubmit={this.HandlePasswordChange}>
                    <Form.Group>
                        <Alert variant="warning">
                            You are changing the password for user: <strong>{this.state.userName}</strong>
                        </Alert>
                    </Form.Group>
                    <Form.Group controlId="currentPassword">
                        <Form.Control 
                            placeholder="Current Password"
                            type="password"
                            value={this.state.currentPassword}
                            onChange={this.HandleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="newPassword">
                        <Form.Control 
                            placeholder="New Password"
                            type="password"
                            value={this.state.newPassword}
                            onChange={this.HandleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="confirmPassword">
                        <Form.Control 
                            placeholder="Confirm Password"
                            type="password"
                            value={this.state.confirmPassword}
                            onChange={this.HandleChange}
                        />
                    </Form.Group>
                    {this.state.isLoading === true ? <Button variant="primary" block disabled><Spinner animation="grow" size="sm" />Update Password</Button> : <Button variant="primary" block disabled={!this.ValidatePassword()} type="submit">Update Password</Button>}
                    <Button variant="secondary" block onClick={() => this.HandleCancel()}>Cancel</Button>
                </Form>
            </div>
        );
    }

    render(){
        if(this.state.samlLogin === true) {
            return(
                <div className="ChangePassword">
                    <img src={logo} width="100" height="100" alt="logo"/><h4>TinyPath</h4>
                    {this.DisplaySamlUser()}
                </div>
            );
        }
        
        return(
            <div className="ChangePassword">
                <img src={logo} width="100" height="100" alt="logo"/><h4>TinyPath</h4>
                {this.state.changeConfirmed === false
                    ? this.DisplayForm()
                    : this.DisplayConfirmation()
                }
            </div>
        );
    }
}


export default ChangePassword;