import React, { Component } from 'react';
import Routes from './Routes';
import { Auth } from "aws-amplify";
import './App.css';

class App extends Component {
  constructor(props){
    super(props);
    
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true
    };
  }
  
  async componentDidMount() {
    Auth.currentAuthenticatedUser().then(user => {
      this.UserHasAuthenticated(true);
    }).catch(e => {});
  }
  
  UserHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }
  
  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      UserHasAuthenticated: this.UserHasAuthenticated
    };
    return (
      <div className="App">
        <Routes childProps={childProps} />
      </div>
    );
  }
}

export default App;
