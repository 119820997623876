import React, { Component } from 'react';
import { Auth } from "aws-amplify";
import { Button, Form, Spinner } from 'react-bootstrap';
import logo from '../logo.svg';


class Login extends Component {
    constructor(props){
        super(props);
        
        this.state = {
            isLoading: false,
            email: "",
            password: "",
            newUser: false,
            newPassword: "",
            confirmPassword: "",
            firstName: "",
            lastName: ""
        };
    }
    
    HandleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }
    
    ValidateLogin(){
        return this.state.email.length > 0 && 
        this.state.password.length > 0;
    }
    
    ValidateNewUser(){
        return this.state.newPassword.length > 0 && 
            this.state.confirmPassword.length > 0 && 
            this.state.firstName.length > 0 && 
            this.state.lastName.length > 0 &&
            this.state.newPassword === this.state.confirmPassword;
    }
    
    HandleSamlLogIn(){
        window.location.assign("https://tinypath.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=gsuite&redirect_uri=https://admin.tinypath.net/samllogin&response_type=TOKEN&client_id=324ddh76a5n291scsv0n23e4l&scope=email%20openid");
    }
    
    HandleLogIn = async event => {
        event.preventDefault();
        
        this.setState({
            isLoading: true
        });
        
        try {
            var response = await Auth.signIn(this.state.email, this.state.password);
            
            if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
                this.setState({
                    newUser: true,
                    isLoading: false
                });
            } else {
                this.props.UserHasAuthenticated(true);
            }
        } catch(e) {
            alert(e['message']);
            this.setState({ isLoading: false });
        }
    }
    
    HandleNewUser = async event => {
        event.preventDefault();
        
        this.setState({
            isLoading: true
        });
        
        var requiredAttributes = {
            "family_name": this.state.lastName,
            "name": this.state.firstName
        };
        
        try {
            await Auth.signIn(this.state.email, this.state.password).then(user => {
                return Auth.completeNewPassword(user, this.state.newPassword, requiredAttributes);
            });
            this.props.UserHasAuthenticated(true);
        } catch(e) {
            alert(e['message']);
            this.setState({ isLoading:false });
        }
    }
    
    HandleForgotPassword(){
        this.props.history.push("/forgotpassword");
    }
    
    DisplayLogin(){
        return(
            <div className="DisplayLogin" style={{ justifyContent:'center', alignItems:'center', display: 'flex' }}>
                <Form onSubmit={this.HandleLogIn}>
                    <Form.Group controlId="email">
                        <Form.Control 
                            placeholder="Email Address"
                            type="email"
                            value={this.state.email}
                            onChange={this.HandleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="password">
                        <Form.Control 
                            placeholder="Password"
                            type="password"
                            value={this.state.password}
                            onChange={this.HandleChange}
                        />
                    </Form.Group>
                    {this.state.isLoading === true ? <Button variant="primary" block disabled><Spinner animation="grow" size="sm" />Log In</Button> : <Button variant="primary" block disabled={!this.ValidateLogin()} type="submit">Login</Button>}
                    <Button variant="outline-secondary" size="sm" block onClick={() => this.HandleSamlLogIn()}>Log In with SAML</Button>
                    <Button variant="link" onClick={() => this.HandleForgotPassword()}>Forgot Password</Button>
                </Form>
            </div>
        );
    }
    
    DisplayNewUser(){
        return(
            <div className="DisplayNewUser" style={{ justifyContent:'center', alignItems:'center', display: 'flex' }}>
                <Form onSubmit={this.HandleNewUser}>
                    <Form.Group controlId="newPassword">
                        <Form.Control 
                            placeholder="New Password"
                            type="password"
                            value={this.state.newPassword}
                            onChange={this.HandleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="confirmPassword">
                        <Form.Control 
                            placeholder="Confirm Password"
                            type="password"
                            value={this.state.confirmPassword}
                            onChange={this.HandleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="firstName">
                        <Form.Control 
                            placeholder="First Name"
                            type="text"
                            value={this.state.firstName}
                            onChange={this.HandleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="lastName">
                        <Form.Control 
                            placeholder="Last Name"
                            type="text"
                            value={this.state.lastName}
                            onChange={this.HandleChange}
                        />
                    </Form.Group>
                    {this.state.isLoading === true ? <Button variant="primary" block disabled><Spinner animation="grow" size="sm" />Confirm</Button> : <Button variant="primary" block disabled={!this.ValidateNewUser()} type="submit">Confirm</Button>}
                </Form>
            </div>
        );
    }
    
    render(){
        return(
            <div className="Login">
                <img src={logo} width="100" height="100" alt="logo"/><h4>TinyPath</h4>
                {this.state.newUser === false
                    ? this.DisplayLogin()
                    : this.DisplayNewUser()
                }
            </div>
        );
    }
}


export default Login;