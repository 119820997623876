import React, { Component } from 'react';
import { API, Auth } from "aws-amplify";
import { Navbar, Nav, NavDropdown, Button, Table, Modal, Form, Spinner } from 'react-bootstrap';
import logo from '../logo.svg';


class DisplayBody extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            objects: [],
            modalDelete: false,
            modalCreate: false,
            deletePath: null,
            isLoading: true,
            modalLoading: false,
            newUrl: null,
            newTarget: "",
            newPath: "",
            userName: ""
        };
    }
    
    async componentDidMount() {
        await this.LoadBody();
    }
    
    async LoadBody(){
        Auth.currentAuthenticatedUser().then(user => {
            if(user.hasOwnProperty('attributes')) {
                this.setState({ userName: user['attributes']['name'] });
            }else {
                var email = (user['username'].split("_"))[1];
                this.setState({
                    userName: email
                });
            }
        });
        
        try {
            var response = await this.ListUrl();
        } catch(e) {
            // alert(e.response.data);
            if(e.response !== undefined){
                alert(e.response.data);
                this.setState({ isLoading: false });
                return;
            } else {
                alert("The service is currently experiencing an unexpected error and your request cannot be completed at this time.");
                this.setState({ isLoading: false });
                return;
            }
        }
        this.setState({
            objects: response['Response'],
            isLoading: false
        });
    }
    
    async HandleDelete(){
        this.setState({ modalLoading: true });
        
        try {
            await this.DeleteUrl(this.state.deletePath);
            
            this.setState({
                modalLoading: false,
                modalDelete: false,
                deletePath: null,
                isLoading: true
            });
            
            this.LoadBody();
        } catch(e) {
            // alert(e.response.data);
            // this.setState({ modalLoading: false });
            if(e.response !== undefined){
                alert(e.response.data);
                this.setState({ modalLoading: false });
                return;
            } else {
                alert("The service is currently experiencing an unexpected error and your request cannot be completed at this time.");
                this.setState({ modalLoading: false });
                return;
            }
        }
    }
    
    async HandleCreate(){
        this.setState({ modalLoading: true });
        
        var requiredAttributes = {};
        
        if(this.state.newPath !== ""){
            requiredAttributes={
                "target": this.state.newTarget,
                "path": this.state.newPath
            };
        } else {
            requiredAttributes={
                "target": this.state.newTarget
            };
        }
        
        try {
            var response = await this.CreateUrl(requiredAttributes);

            this.setState({
                modalLoading: false,
                newTarget: "",
                newPath: "",
                isLoading: true,
                newUrl: response
            });
            
            this.LoadBody();
        } catch(e) {
            // alert(e.response.data);
            // this.setState({ modalLoading: false });
            if(e.response !== undefined){
                alert(e.response.data);
                this.setState({ modalLoading: false });
                return;
            } else {
                alert("The service is currently experiencing an unexpected error and your request cannot be completed at this time.");
                this.setState({ modalLoading: false });
                return;
            }
        }
    }
    
    ValidateFormCreate(){
        return this.state.newTarget.startsWith("http://") || this.state.newTarget.startsWith("https://");
    }
    
    HandleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }
    
    HandleLogout = async event => {
        try {
            await Auth.signOut();
            
            this.props.UserHasAuthenticated(false);
            this.props.history.push("/login");
        } catch(e) {
            alert(e['message']);
            this.props.history.push("/login");
        }
    }
    
    HandleChangePassword(){
        this.props.history.push("/changepassword");
    }
    
    HandleModalOpenCreate(){
        this.setState({ modalCreate: true });
    }
    
    HandleModalCloseCreate(){
        this.setState({
            modalCreate: false,
            newTarget: "",
            newPath: "",
            newUrl: null
        });
    }
    
    HandleModalOpenDelete(path){
        this.setState({
            modalDelete: true,
            deletePath: path
        });
    }
    
    HandleModalCloseDelete(){
        this.setState({
            modalDelete: false,
            deletePath: null
        });
    }
    
    ListUrl() {
        return API.get("urlSend", "/list-url");
    }
    
    CreateUrl(requiredAttributes) {
        return API.post("urlSend", "/create-url", {body: requiredAttributes});
    }
    
    DeleteUrl(path) {
        return API.del("urlSend", "/delete-url/" + path);
    }
    
    ModalDelete(){
        return(
            <Modal show={this.state.modalDelete} centered onHide={() => this.HandleModalCloseDelete()}>
                <Modal.Header>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this URL? This may take up to 24 hours. <br /><br />
                    <b>Path:</b> {this.state.deletePath}
                </Modal.Body>
                <Modal.Footer>
                    {this.state.modalLoading === true ? <Button variant="danger" block disabled><Spinner animation="grow" size="sm" />Delete</Button> : <Button variant="danger" block onClick={() => this.HandleDelete()}>Delete</Button>}<br />
                    <Button variant="secondary" block onClick={() => this.HandleModalCloseDelete()}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        );
    }
    
    ModalCreate(){
        if (this.state.newUrl !== null) {
            return(
                <Modal show={this.state.modalCreate} centered onHide={() => this.HandleModalCloseCreate()}>
                    <Modal.Header>
                        <Modal.Title>New URL</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h5><a href={this.state.newUrl}>{this.state.newUrl}</a></h5>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" block onClick={() => this.HandleModalCloseCreate()}>Close</Button><br />
                    </Modal.Footer>
                </Modal>
            );
        }
        
        return(
            <Modal show={this.state.modalCreate} centered onHide={() => this.HandleModalCloseCreate()}>
                <Modal.Header>
                    <Modal.Title>New URL</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="newTarget">
                            <Form.Label>Target</Form.Label>
                            <Form.Control
                                placeholder="https://www.example.com/mypage"
                                value={this.state.newTarget}
                                onChange={this.HandleChange}
                            />
                            <Form.Text className="text-muted">Required: A valid target URL starting with http:// or https://</Form.Text>
                        </Form.Group>
                        <Form.Group controlId="newPath">
                            <Form.Label>Path</Form.Label>
                            <Form.Control 
                                value={this.state.newPath}
                                onChange={this.HandleChange}
                            />
                            <Form.Text className="text-muted">Optional: A desired custom path. For example "mysite"</Form.Text>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" block onClick={() => this.HandleModalCloseCreate()}>Cancel</Button><br />
                    {this.state.modalLoading === true ? <Button variant="success" block disabled><Spinner animation="grow" size="sm" />Create</Button> : <Button variant="success" block disabled={!this.ValidateFormCreate()} onClick={() => this.HandleCreate()}>Create</Button>}
                </Modal.Footer>
            </Modal>
        );
    }
    
    DisplayRow(objects){
        if(objects === "NoObjectsPresent"){
            return;
        }
        
        objects.sort(function(a,b) {return (a['path'] > b['path']) ? 1 : ((b['path'] > a['path']) ? -1 : 0); });
        
        return [].concat(objects).map(
            (object, i) =>
                <tr key={object['path']}>
                    <td>{object['path']}</td>
                    <td>{object['target']}</td>
                    <td><Button variant="danger" size="sm" onClick={() => this.HandleModalOpenDelete(object['path'])}>Delete</Button></td>
                </tr>
        );
    }
    
    DisplayTable(){
        return(
            <div>
                <Table striped bordered>
                    <thead>
                        <tr>
                            <th>Path</th>
                            <th>Target</th>
                            <th width="30px">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.DisplayRow(this.state.objects)}
                    </tbody>
                </Table>
            </div>
        );
    }
    
    render() {
        return(
            <div className="Home">
                <div className="header">
                    <Navbar bg="dark" variant="dark" sticky="top">
                        <Navbar.Brand href="#">
                            <img src={logo} width="30" height="30" className="d-inline-block align-top" alt="logo"/>TinyPath
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                            <Nav className="justify-content-end">
                                <Button variant="outline-primary" size="sm" onClick={() => this.HandleModalOpenCreate()}>New Link</Button>
                                <NavDropdown alignRight title={this.state.userName} id="basic-nav-dropdown">
                                    <NavDropdown.Item onClick={() => this.HandleChangePassword()}>Change Password</NavDropdown.Item>
                                    <NavDropdown.Item onClick={this.HandleLogout}>Logout</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
                <div className="Body">
                    {this.state.isLoading === true ? <h4><Spinner animation="border" /><strong>Loading...</strong></h4> : this.DisplayTable()}
                </div>
                <div className="ModalDelete">
                    {this.ModalDelete()}
                </div>
                <div className="ModalCreate">
                    {this.ModalCreate()}
                </div>
            </div>
        );
    }
}

export default DisplayBody;