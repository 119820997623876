import React, { Component } from 'react';
import { Hub } from "aws-amplify";

class SamlLogin extends Component {
    constructor(props){
        super(props);
        
        this.state = {
            isLoading: true
        };
    }
    
    componentDidMount() {
        Hub.listen("auth", (data) => {
            switch (data.payload.event) {
                case "signIn":
                    this.props.UserHasAuthenticated(true);
                    break;
                case "signOut":
                    this.props.UserHasAuthenticated(false);
                    break;
                case "signIn_failure":
                    this.setState({ isLoading: false });
                    break;
                default:
                    break;
            }
        });
    }
    
    DisplayLoading() {
        return(
            <div>
                <h2>Logging In...</h2>
            </div>
        );
    }
    
    DisplayError() {
        return(
            <div>
                <h2>An Error Occurred</h2>
            </div>
        );
    }
    
    render(){
        return(
            <div className="SamlLogin">
                {this.state.isLoading === true
                    ? this.DisplayLoading()
                    : this.DisplayError()
                }
            </div>
        );
    }
}

export default SamlLogin;